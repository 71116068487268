<template>
  <div>
    <div
      class="searchcontainer"
      :class="modelValue.length > 0 ? 'primary_bg_color' : 'sixth_bg_color'"
    >
      <h2 class="contrast_color">{{ title }}</h2>

      <div class="searchbox contrast_bg_color">
        <input
          type="search"
          name="exhibit"
          :placeholder="placeholder"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <!-- list="exhibitorlist" -->
        <!-- v-model="value" -->
        <i class="fas fa-search"></i>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Default text',
      },
      placeholder: {
        type: String,
        default: 'Default text',
      },
      modelValue: {
        type: String,
        default: '',
      },
    },
    emits: ['update:modelValue'],
  }
</script>

<style scoped>
  .searchcontainer {
    width: 95%;
    margin: 0 auto;
    text-align: left;
    padding: 10px;
  }

  .searchcontainer h2,
  .searchbox {
    display: inline-block;
    width: 50%;
    margin: 0;
    vertical-align: middle;
  }

  .searchbox input {
    line-height: 1.5;
    width: 90%;
    box-sizing: border-box;
    padding: 10px;
    height: 50px;
    font-family: inherit;
    border: 0;
  }
  .searchbox input:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  input[type='search']::-ms-clear,
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
  ::placeholder {
    font-size: 15px;
  }

  @media screen and (max-width: 500px) {
    .searchcontainer h2 {
      font-size: 18px;
    }
    .searchcontainer h2,
    .searchbox {
      width: 100%;
      display: block;
    }
  }
</style>
