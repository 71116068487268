<template>
  <div>
    <Title :text="translations.nav_presentations" />
    <div class="viewcontent">
      <div
        class="img"
        :style="{
          'background-image': 'url(' + definePicture + ')',
        }"
      ></div>

      <p class="description">
        {{ translations.text_trends }}
      </p>
      <SearchList :data="trends" :type="'trend'" />
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import Search from '@/components/Search.vue'
  import SearchList from '@/components/SearchList.vue'

  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'Investment',
    components: {
      Title,
      Search,
      SearchList,
    },

    computed: {
      ...mapState(['trends', 'trendHeader', 'files', 'translations']),
      definePicture() {
        let result = require('@/assets/baner/baner.jpg')
        if (this.trendHeader) {
          result = this.files + '/property/' + this.trendHeader
        }
        return result
      },
    },
    methods: {
      ...mapActions(['getTrends']),
    },
    created() {
      this.getTrends()
    },
  }
</script>

<style scoped>
  .img {
    width: 100%;
    position: relative;
    height: 200px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
  .description {
    text-align: left;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 930px) {
    .img {
      height: 178px;
    }
    p {
      padding: 10px;
    }
  }
  @media screen and (max-width: 650px) {
    .img {
      height: 156px;
    }
  }
</style>
